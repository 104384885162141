<template>
  <zingchart :data="myData" :series="items" v-if="processed"></zingchart>
</template>


<script>
import { mapActions } from "vuex";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";
import { months } from "moment";
export default {
  name: "TattooerMonthlyGraph",
  components: {
    zingchart: zingchartVue,
  },
  data() {
    return {
      processed: false,
      items: [],
      myData: {
        type: "line",
        globals: {
          fontFamily: "Roboto Mono"
        },
        backgroundColor:'none',
        "scale-x": {
          labels: this.$t("months"),
          "max-items": 13,
          item: {
            angle: -70,
          },
        },
        "scale-y": {
          guide: {
            "line-color": "var(--v-gris1-base)"
          }
        },
        plotarea: {
          adjustLayout: true,
        },
      },
    };
  },
  methods: {
    ...mapActions("reports", ["getAdminMonthlyGraph"]),
    fetchAppointmentMonthlyGraph() {
      this.getAdminMonthlyGraph({ options: this.options }).then((data) => {
        let items = [null];
        let info;
        let total = 0;
        for (let i = 1; i <= 12; i++) {
          info = data.find((x) => x.month == i);
          if (info) {
            total += info.numAppointments;
          }
          items.push(total);
        }
        this.items.push({
          values: items,
          "line-color": "var(--v-primary-base)",
          marker: {
            "background-color": "var(--v-primary-base)",
            "border-color": "transparent"
          },
        });
        this.processed = true;
      });
    },
  },
  mounted() {
    this.fetchAppointmentMonthlyGraph();
  },
};
</script>